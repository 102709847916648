/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap')
@import 'tailwindcss/base'
@import 'tailwindcss/components'
@import 'tailwindcss/utilities'
@mixin icon($url)
  background: url($url)
  background-size: cover
  height: 20px
  width: 20px



@layer base
  *
    @apply font-noto


mat-progress-spinner circle, mat-spinner circle
  stroke:#1C593D

.ngx-mat-file-input input
  background-color: #f5f5f5
  width: 100%

.icon-home
  @include icon('assets/icons/homepage-icon.png')
.icon-cambios
  @include icon('assets/icons/settings-gear-icon.png')
.icon-beneficiarios
  @include icon('assets/icons/signup-icon.png')
.icon-pagadores
  @include icon('assets/icons/cashback-usd-icon.png')
.icon-criptomoneda
  @include icon('assets/icons/bitcoin-black-icon.png')
.icon-zelle
  @include icon('assets/icons/zelle.png')
.icon-alert
  @include icon('assets/icons/alert.png')
  background-repeat: no-repeat
  background-size: 20px 17px
.icon-copy
  @include icon('assets/icons/copy.png')
  background-repeat: no-repeat
  background-size: 15px 20px

.no-border
  border: 0 !important
